<template>
    <div>
      <form  @submit.prevent="handleSubmit">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">General rental configurations</h4>

            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="riskOfDamage">own risk per damage (€)</label>
                  <input type="number" step='0.01' id="riskOfDamage" class="form-control" placeholder="Enter risk of damage here"
                         v-model="totalRiskOfDamageInEuros"
                         required
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="riskOfDamage">Set damage fee per day when risk is reduced (€)</label>
                  <input type="number" step='0.01' id="pricePerDayWhenRiskIsReduced" class="form-control" placeholder="set price here"
                         required
                         v-model="feePerDayInEuros"
                  >
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="riskOfDamageWhenReduced">Reduced risk of damage (when reduced) (€)</label>
                  <input type="number" step='0.01' id="riskOfDamageWhenReduced" class="form-control" placeholder="Enter risk of damage here"
                         required v-model="reducedRiskOfDamageInEuros"
                  >
                </div>
              </div>

            </div>

            <hr>

            <h4 class="card-title"> Price configuration</h4>
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="pricePerWeek">Price per week (When duration is greater or equal to one week)</label>
                  <input type="number" step='0.01' id="pricePerWeek" class="form-control" placeholder="set price here"
                         required
                         v-model="pricePerWeek"
                  >
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="pricePerDay">Price per day (When duration is in days)</label>
                  <input type="number" step='0.01' id="pricePerDay" class="form-control" placeholder="set price here"
                         required
                         v-model="pricePerDay"
                  >
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="pricePerHour">Price per hour (When duration is few hours)</label>
                  <input type="number" step='0.01' id="pricePerHour" class="form-control" placeholder="set price here"
                         required
                         v-model="pricePerHour"
                  >
                </div>
              </div>

            </div>

            <hr>

            <div class="text-center">
              <div class="form-group">
                <button type="submit" class="btn btn-primary">Save Configurations</button>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>
</template>

<script>
export default {
  name: "config",
  data() {
    return {
      totalRiskOfDamageInEuros: '',
      feePerDayInEuros: '',
      reducedRiskOfDamageInEuros: '',
      pricePerWeek: '',
      pricePerDay: '',
      pricePerHour: '',
    }
  },
  methods: {
    handleSubmit(){
      const payload = {
        'gross_risk_of_damage': this.totalRiskOfDamageInEuros,
        'reduced_risk_of_damage': this.reducedRiskOfDamageInEuros,
        'recurring_price_per_day': this.feePerDayInEuros,
        'price_per_week': this.pricePerWeek,
        'price_per_day': this.pricePerDay,
        'price_per_hour': this.pricePerHour,
      }

      this.$store.dispatch('updateRentalConfigs', payload);
    }
  },
  watch: {
    '$store.getters.GET_RENTAL_SETUPS': function (config) {
      console.log(config);
        this.totalRiskOfDamageInEuros = config['gross_risk_of_damage']
        this.reducedRiskOfDamageInEuros = config['reduced_risk_of_damage']
        this.feePerDayInEuros = config['recurring_price_per_day']

        this.pricePerWeek = config['price_per_week']
        this.pricePerDay = config['price_per_day']
        this.pricePerHour = config['price_per_hour']
    }
  },
  created() {
    this.$store.dispatch('getRentalConfigs');
  }
}
</script>

<style scoped>

</style>